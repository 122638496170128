import Moment from "moment"
import Axios from "axios"

export const calculDates = (
  dateNaissance,
  regimeAssurance,
  situationProfessionelle,
  hasChildren,
  config
) => {
  const url = `${
    process.env.GATSBY_MGCGESTION_API_URL
  }/public/dates/calcul?dateNaissance=${Moment(dateNaissance).format(
    "YYYY-MM-DD"
  )}&regimeAssurance=${regimeAssurance}&situationProfessionelle=${situationProfessionelle}&hasChildren=${hasChildren}`
  /*        const url = `http://localhost:8080/api/public/dates/calcul?dateNaissance=${Moment(dateNaissance).format(
              "YYYY-MM-DD")}&regimeAssurance=${regimeAssurance}&situationProfessionelle=${situationProfessionelle}&hasChildren=${hasChildren}`*/
  return Axios.get(url, config)
}

export const calculDatesMinMax = (
  dateNaissance,
  regimeAssurance,
  situationProfessionelle,
  hasChildren,
  hasRIA,
  config,
  prospectId,
  dateEcheance,
  ancienneteMutuelleActuelleSup12Mois,
  mutuelleActuelle,
  resilierMutuelleActuelle,
  contratComplementaireSante
) => {
  let url = `${
    process.env.GATSBY_MGCGESTION_API_URL
  }/public/dates/minmax?dateNaissance=${Moment(dateNaissance).format(
    "YYYY-MM-DD"
  )}&regimeAssurance=${regimeAssurance}&situationProfessionelle=${situationProfessionelle}&hasChildren=${hasChildren}&hasRIA=${hasRIA}`

     if (ancienneteMutuelleActuelleSup12Mois !== null && ancienneteMutuelleActuelleSup12Mois !== undefined){
       url = `${url}&ancienneteMutuelleActuelleSup12Mois=${ancienneteMutuelleActuelleSup12Mois}`
     }

     console.log(prospectId);

     if (prospectId !== null && prospectId !== undefined){
      url = `${url}&prospectId=${prospectId}`
    }

     if (resilierMutuelleActuelle !== null && resilierMutuelleActuelle !== undefined){
       url = `${url}&resilierMutuelleActuelle=${resilierMutuelleActuelle}`
     }

     if (contratComplementaireSante !== null && contratComplementaireSante !== undefined){
      url = `${url}&contratComplementaireSante=${contratComplementaireSante}`
    }

     if (mutuelleActuelle !== null && mutuelleActuelle !== undefined){
       url = `${url}&mutuelleActuelle=${mutuelleActuelle}`
     }

    if (dateEcheance !== null && dateEcheance !==undefined){
        url = `${url}&dateEcheance=${dateEcheance}`
    }

    return Axios.get(url)
}