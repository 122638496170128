import React from "react"
import { Formik, Field } from "formik"
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap"
import QuotationLandingValidation from "./quotationLandingValidation"
import QuotationService from "../../services/quotationService"
import MgcDateField from "../mgcDateField"
import MgcSelectField from "../mgcSelectField"
import QuotationAGCOLTER from "../quotationAGCOLTER"
import QuotationTNS from "../quotationTNS"
import MgcRadioToggleButtonGroup from "../mgcRadioToggleButtonGroup"
import MgcRadioToggleButton from "../mgcRadioToggleButton"
import QuotationConjoint from "../quotationConjoint"
import QuotationChildren from "../quotationChildren"
import informationIcon from "../../images/icon-information.png"
import QuotationBesoins from "../quotation/quotationBesoins"
import QuotationMapper from "../../services/quotationMapper"
import MgcNewDateField from "../mgcNewDateField";
class QuotationLanding extends React.Component {
  situationPro
  setFieldValue
  valuesForAd
  besoinTNS
  values = this.props.values
  initialValues = this.props.initialValues
  state = {
    rA: [{ id: 1, libelle: "" }],
    sP: ["test"][{}],
    datesEffet: "",
    getDate: false,
    tns: false,
    agentTerritorial: false,
    questions: [{}],
  }
  tabEnfantplus1An = [
    { id: 1, libelle: "1 enfant" },
    { id: 2, libelle: "2 enfants et plus" },
  ]
  componentDidUpdate(prevProps) {
    if (this.props.rA !== prevProps.rA) {
      let regime = []
      this.props.rA
        ? this.props.rA.data.map(e => {
          e.id = e.code
          regime.push(e)
          this.setState({ rA: regime })
        })
        : (regime = [{ id: 1, libelle: "" }])
    }
    if (this.props.sP !== prevProps.sP) {
      this.setState({ sP: this.props.sP })
    }
  }
  submitStepOne(values) {
    sessionStorage.setItem("devisStepOne", JSON.stringify(values))
    sessionStorage.setItem("regimeAss", JSON.stringify(this.state.rA))
    window.location.assign("/mutuelle-sante/devis")
  }
  changeStateDateEffet = tab => {
    if (tab) {
      this.setState({ datesEffet: tab })
    }
  }

  checkTNS(val) {
    if (this.state.sP[val.regimeAssuranceMal]) {
      let tns = this.state.sP[val.regimeAssuranceMal].filter(
        e => e.id === val.situationPro
      )[0]
        ? this.state.sP[val.regimeAssuranceMal].filter(
          e => e.id === val.situationPro
        )[0].tns
        : false
      this.setState({ tns: tns })
      val.tns = tns
      if (this.tns !== true) {
        let agentTerritorial = this.state.sP[val.regimeAssuranceMal].filter(
          e => e.id === val.situationPro
        )[0]
          ? this.state.sP[val.regimeAssuranceMal].filter(
            e => e.id === val.situationPro
          )[0].agentTerritorial
          : false
        this.setState({ agentTerritorial: agentTerritorial })
        val.agentTerritorial = agentTerritorial
      }
    }
  }

  handleRegimeAssuranceMalChange = value => {
    this.setFieldValue("regimeAssuranceMal", value)
    if (
      this.valuesForAd.dateNaissance &&
      value &&
      this.valuesForAd.situationPro.length > 0
    ) {
      QuotationService.getDateEffet(
        this.valuesForAd.dateNaissance,
        value,
        this.valuesForAd.situationPro,
        this.valuesForAd.childrenChecked
      ).then(result => {
        this.changeStateDateEffet(result)
      })
    }
  }

  handleChildrenCheckedlChange(value) {
    if (
      this.valuesForAd.dateNaissance &&
      this.valuesForAd.regimeAssuranceMal.length > 0 &&
      this.valuesForAd.situationPro.length > 0
    ) {
      QuotationService.getDateEffet(
        this.valuesForAd.dateNaissance,
        this.valuesForAd.regimeAssuranceMal,
        this.valuesForAd.situationPro,
        this.valuesForAd.childrenChecked
      ).then(result => {
        this.changeStateDateEffet(result)
      })
    }
  }

  handleSituationProChange = value => {
    this.setFieldValue("situationPro", value)
    if (
      this.valuesForAd.dateNaissance &&
      this.valuesForAd.regimeAssuranceMal.length > 0 &&
      value
    ) {
      QuotationService.getDateEffet(
        this.valuesForAd.dateNaissance,
        this.valuesForAd.regimeAssuranceMal,
        value,
        this.valuesForAd.childrenChecked
      ).then(result => {
        this.changeStateDateEffet(result)
      })
    }
  }
  handleDateNaissanceChange = value => {
    this.setFieldValue("dateNaissance", value)
    if (
      value &&
      this.valuesForAd.regimeAssuranceMal.length > 0 &&
      this.valuesForAd.situationPro.length > 0
    ) {
      QuotationService.getDateEffet(
        value,
        this.valuesForAd.regimeAssuranceMal,
        this.valuesForAd.situationPro,
        this.valuesForAd.childrenChecked
      ).then(result => {
        this.changeStateDateEffet(result)
      })
    }
  }
  onChangeDateEffet = dateEffet => {
    this.setState({ questions: [] })
    if (!!this.valuesForAd.situationPro) {
      const devis = QuotationMapper.toDevisDTO(this.valuesForAd, dateEffet)
      QuotationService.getDDAQuestions(devis).then(r => {
        this.setState({ questions: r.data })
        if (
          !!r.data.find(question => {
            return question.path === "couvertureZen"
          })
        ) {
          //setFieldValue("zen", true)
        }
      })
    }
  }

  componentDidMount() {
    this.besoinTNS = [
      {
        id: "FRAIS_HOSPITALISATION",
        libelle: "Être couvert en cas d'hospitalisation uniquement",
      },
      { id: "ECONOMIQUE", libelle: "Assurer l'essentiel à petit prix" },
      { id: "EQUILIBREE", libelle: "Bénéficier d'une couverture équilibrée" },
      { id: "RENFORCEE", libelle: "Garantir une sécurité maximale" },
    ]
  }
  getValue(val) {
    this.valuesForAd = val
  }
  constructor(props) {
    super(props)
    this.setFieldValue = null
    this.state = {
      regimeAssurance: [1, 2],
      situationProfessionelle: [1, 2],
    }
  }

  handleBlur(val) {
    this.getValue(val)
  }

  render() {
    return (
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={QuotationLandingValidation}
          onSubmit={(values, actions) => {
            this.submitStepOne(values)
            actions.setSubmitting(false)
          }}
          render={props => (
            <form onSubmit={props.handleSubmit}>
              {(this.setFieldValue = props.setFieldValue)}
              <section
                className={"landing-quot"}
                onClick={() => this.getValue(props.values)}
              >
                <div className={"pink-header-landing"}>
                  <h3>
                    Complétez le formulaire pour obtenir un devis personnalisé !
                        </h3>
                  <div id="carre" />
                </div>
                <div className={"body-form-landing"}>
                  <span className={"pink-title-quot"}>
                    PERSONNE(S) À COUVRIR
                  </span>
                  <div className={"black-subtitle-quot"}>
                    Adhérent principal
                        </div>
                  <Row>
                    {props.errors.dateNaissance &&
                      props.values.dateNaissance ? (
                        <Col xs={11} className="alert-majeur">
                          {" "}
                          <span className="">
                            Il n'est pas possible d'adhérer avant 18 ans
                        </span>
                        </Col>
                      ) : (
                        ""
                      )}
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <MgcNewDateField
                        dirty={props.dirty}
                        errors={props.errors}
                        name="dateNaissance"
                        className=""
                        withoutOffset={true}
                        label={"Date de naissance"}
                        setFieldValue={props.setFieldValue}
                        onChange={this.handleDateNaissanceChange}
                        onBlur={() => this.handleBlur(props.values)}
                        values={props.values}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} className={"r-a"}>
                      <MgcSelectField
                        dirty={props.dirty}
                        name="regimeAssuranceMal"
                        errors={props.errors}
                        firstOption="- Régime d'assurance maladie -"
                        className="form-control rA"
                        onChange={this.handleRegimeAssuranceMalChange}
                        onBlur={() => this.handleBlur(props.values)}
                        table={this.state.rA ? this.state.rA : this.state.rA}
                        setFieldValue={props.setFieldValue}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xs={12}
                      onClick={() => {
                        this.checkTNS(props.values)
                      }}
                    >
                      <MgcSelectField
                        dirty={props.dirty}
                        name="situationPro"
                        errors={props.errors}
                        firstOption="- Situation professionnelle -"
                        className="form-control"
                        setFieldValue={props.setFieldValue}
                        onChange={this.handleSituationProChange}
                        onBlur={() => this.handleBlur(props.values)}
                        assusranceMalUnselected={
                          !props.values.regimeAssuranceMal
                        }
                        table={
                          this.state.sP
                            ? this.state.sP[props.values.regimeAssuranceMal]
                            : ["test"][{}]
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    {this.state.agentTerritorial ? (
                      <Col xs={12}>
                        <QuotationAGCOLTER
                          errors={props.errors}
                          dirty={props.dirty}
                          values={props.values}
                          touched={props.touched}
                          setFieldValue={props.setFieldValue}
                          landing={true}
                          value={props.values.dejaCouvert}
                          dirty={false}
                        />
                      </Col>
                    ) : (
                        ""
                      )}
                    {this.state.tns ? (
                      <Col xs={12} className={'landing-agentTerritoral'}>
                        <QuotationTNS
                          errors={props.errors}
                          touched={props.touched}
                          dirty={props.dirty}
                          setFieldValue={props.setFieldValue}
                          values={props.values}
                          notOffset={true}
                          landing={true}
                        />
                      </Col>
                    ) : (
                        ""
                      )}
                  </Row>
                  <Row>
                    <Col xs={12} className="m-bottom">
                      <span>
                        Êtes-vous une Personne Politiquement Exposée ?
                      </span>
                    </Col>
                  </Row>
                  <Row className="question-y-n">
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-bottom">
                          Personne Politiquement Exposée : Il s’agit de toute
                          personne exerçant ou ayant exercé au cours des 12
                          derniers mois une fonction politique, juridictionnelle
                          ou administrative pour le compte d’un Etat ou d’une
                          Institution internationale publique ou un proche
                          (membre direct de la famille ou personne étroitement
                          associée) d’une personne exerçant ou ayant exercé une
                          telle fonction.
                                </Tooltip>
                      }
                    >
                      <img src={informationIcon} />
                    </OverlayTrigger>
                    <Col xs={12} />
                    <Col className="quotation-radio quotation-radio-politique">
                      <MgcRadioToggleButtonGroup
                        id="politiquementExpose"
                        error={props.errors.politiquementExpose}
                        touched={props.touched.politiquementExpose}
                        withoutOffset={true}
                        dirty={!props.dirty}
                      >
                        <Field
                          component={MgcRadioToggleButton}
                          name="politiquementExpose"
                          id="true"
                          label={"Oui"}
                        />
                        <Field
                          component={MgcRadioToggleButton}
                          name="politiquementExpose"
                          id="false"
                          label={"Non"}
                        />
                      </MgcRadioToggleButtonGroup>
                    </Col>
                  </Row>
                  <span className={"black-subtitle-quot"}>
                    Autre(s) bénéficiaire(s)
                  </span>
                  <Row>
                    <Col>
                      <Field
                        className="check "
                        name="conjointChecked"
                        type="checkbox"
                        component="input"
                        id="conjoint"
                      />
                      <label htmlFor="conjoint" className={"conjoint-enfant"}>
                        Mon conjoint
                            </label>
                    </Col>
                  </Row>
                  {props.values.conjointChecked ? (
                    <QuotationConjoint
                      dirty={props.dirty}
                      errors={props.errors}
                      tab1={
                        this.state.rA.data ? this.state.rA.data : this.state.rA
                      }
                      tab2={this.state.sP}
                      values={props.values}
                      setFieldValue={props.setFieldValue}
                    />
                  ) : (
                      ""
                    )}
                  <Row>
                    <Col>
                      <Field
                        className="check"
                        name="childrenChecked"
                        type="checkbox"
                        component="input"
                        id="children"
                        onClick={() =>
                          this.handleChildrenCheckedlChange(props.values)
                        }
                      />
                      <label htmlFor="children" className={"conjoint-enfant"}>
                        Mes enfants
                            </label>
                    </Col>
                  </Row>
                  {props.values.childrenChecked ? (
                    <QuotationChildren
                      tab={this.tabEnfantplus1An}
                      tabRa={
                        this.state.rA.data ? this.state.rA.data : this.state.rA
                      }
                      values={props.values}
                      dirty={props.dirty}
                      errors={props.errors}
                      setFieldValue={props.setFieldValue}
                    />
                  ) : (
                      ""
                    )}
                  <span className={"pink-title-quot"}>
                    DATE DE PRISE D'EFFET DU CONTRAT
                  </span>
                  <Row>
                    <Col xs={12}>
                      <MgcSelectField
                        setFieldValue={props.setFieldValue}
                        dirty={props.dirty}
                        errors={props.errors}
                        name="debutContrat"
                        firstOption=""
                        className="form-control"
                        table={
                          this.state.datesEffet
                            ? this.state.datesEffet
                            : [{ id: "", libelle: "" }]
                        }
                        onChange={this.onChangeDateEffet}
                      />
                    </Col>
                  </Row>
                  {this.state.questions && <QuotationBesoins
                    errors={props.errors}
                    touched={props.touched}
                    questions={this.state.questions}
                  />}
                  <Row>
                    <Col
                      className=""
                      xs={{ span: 10, offset: 1 }}
                      lg={{ span: 6, offset: 3 }}
                    >
                      <button
                        type="submit"
                        className={` ${!props.isValid ? "btn-disabled" : "btn-submit"
                          }`}
                        disabled={!props.isValid}
                      >
                        TARIFICATION
                            </button>
                    </Col>
                  </Row>
                </div>
              </section>
            </form>
          )}
        />
      </div>
    )
  }
}
export default QuotationLanding

let initialValues = {
  dateNaissance: null,
  regimeAssuranceMal: [],
  situationPro: [],
  debutContrat: "",
  depHonnoraires: "",
  auditif: "",
  hospitalisation: "",
  dentaires: "",
  lunettes: "",
  politiquementExpose: "",
  conjointChecked: "",
  childrenChecked: false,
  regimeAssuranceMalConjoint: "",
  dateNaissanceConjoint: "",
  situationProConjoint: "",
  salariesTNS: "",
  civilite: "",
  adherentNom: "",
  adherentPrenom: "",
}
