import React, { Component } from "react"
import Blocks from "./blocks/blocks"
import Layout from "../components/layout"
import Axios from "axios"
import QuotationLanding from "../components/landingQuotation/quotationLanding"
import UrlService from "../services/global/urlService"

class TemplateLandingPageWithQuot extends Component {
    componentDidMount() {
        URL = `${process.env.GATSBY_MGCGESTION_API_URL}/public/regime-assurances/actif`
/*            URL ="http://localhost:8080/api/public/regime-assurances/actif"*/
                Axios.get(URL)
            .then(result => {
                this.setState({ regimeAssurance: result })
            })
            .then(() => {
                URL = `${process.env.GATSBY_MGCGESTION_API_URL}/public/situation-professionnelles/actives`
/*                        URL ="http://localhost:8080/api/public/situation-professionnelles/actives"*/
                Axios.get(URL).then(result => {
                    let situationPro = []
                    let situationProObject = {}
                    for (let o = 0; o < this.state.regimeAssurance.data.length; o++) {
                        situationPro[this.state.regimeAssurance.data[o].code] = []
                        situationProObject[this.state.regimeAssurance.data[o].code] = []
                        for (let i = 0; i < result.data.length; i++) {
                            for (let j = 0; j < result.data[i].regimeAssurances.length; j++) {
                                if (
                                    result.data[i].regimeAssurances[j].code ==
                                    this.state.regimeAssurance.data[o].id
                                ) {
                                    result.data[i].id = result.data[i].code
                                    situationPro[this.state.regimeAssurance.data[o].code].push(
                                        result.data[i]
                                    )
                                    situationProObject[this.state.regimeAssurance.data[o].code].push(
                                        result.data[i]
                                    )
                                }
                            }
                        }
                    }
                    sessionStorage.setItem("situationPro", JSON.stringify(situationProObject))
                    this.setState({ situationProfessionelle: situationPro })

                })
            })
    }
    values = {
        dateNaissance: null,
        regimeAssuranceMal: [],
        situationPro: [],
        debutContrat: "",
        depHonnoraires: "",
        auditif: "",
        hospitalisation: "",
        dentaires: "",
        lunettes: "",
        politiquementExpose: "",
        conjointChecked: "",
        childrenChecked: false,
        regimeAssuranceMalConjoint: "",
        dateNaissanceConjoint: "",
        situationProConjoint: "",
        salariesTNS: "",
        civilite: "",
        adherentNom: "",
        adherentPrenom: "",
        dejaCouvert: null,
    }
    constructor(props) {
        super(props)
        this.state = {
            regimeAssurance: [1, 2],
            situationProfessionelle: [1, 2],
        }
    }
    render() {
        const { pageContext: context } = this.props
        const { page } = context
        const { media } = context
        const titleSeo = page.titleSEO || ""
        const keywords = page.keywords ? page.keywords.split() : []
        const {tracker, canonicalUrl} = page
        const canonical = UrlService.addSlashToEndOfUrl(canonicalUrl)
        const firstSimpleBlock = page.blocks.find(block => block.__typename == "jhipster_SimpleBlockDTO")
        if(firstSimpleBlock) {
            page.blocks[page.blocks.indexOf(firstSimpleBlock)].first = true 
        }
        return (
            <Layout
                titleSeo={titleSeo}
                keywordsSeo={keywords}
                description={page.description}
                tracker={tracker}
                canonical={canonical}
            >
                <div className="landing-with-quot">
                    <div id="blocs">
                        <QuotationLanding
                            rA={this.state.regimeAssurance}
                            sP={this.state.situationProfessionelle}
                            values={this.values}
                        />
                        {/*<div className={"btn-quot-landing"}>
                            <a href="/devis" className="buttonDevis">
                                Demande de devis
                            </a>
                        </div>*/}
                        <Blocks data={page.blocks} media={media} landing={this.props.pageContext.landing} title={page.title}/>

                    </div>
                </div>
            </Layout>
        )
    }
}

export default TemplateLandingPageWithQuot
