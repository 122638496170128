import Moment from "moment"
import { MgcgestionTokenService } from "./mgcgestionTokenService"
import Axios from "axios"
import { calculDates, calculDatesMinMax } from "./calculDatesUtils"

export default class QuotationService {
  static CONFIG = MgcgestionTokenService.getMgcAdminConfig()

  static getDateEffet(
    dateNaissance,
    regimeAssuranceMal,
    situationPro,
    childrenChecked
  ) {
    return calculDates(
      dateNaissance,
      regimeAssuranceMal,
      situationPro,
      childrenChecked,
      MgcgestionTokenService.getMgcAdminConfig()
    )
      .then(result => {
        return this.createTableDateEffet(result.data)
      })
      .catch(() => {
        return [{ id: "1", libelle: "" }]
      })
  }

  static getDatesMinMax(
    dateNaissance,
    regimeAssuranceMal,
    situationPro,
    childrenChecked
  ) {
    return calculDatesMinMax(
      dateNaissance,
      regimeAssuranceMal,
      situationPro,
      childrenChecked,
      false,
      MgcgestionTokenService.getMgcAdminConfig(),
      null,
      null,
      null,
      null,
      null,
      null
    )
  }

  static createTableDateEffet(dates) {
    return dates.map(date => {
      return {
        id: date,
        libelle: Moment(date).format("DD/MM/YYYY"),
      }
    })
  }

  static getDDAQuestions(devis, dateEffet) {
    const url = `${
      process.env.GATSBY_MGCGESTION_API_URL
    }/public/devis/dda-questions?dateEffet=${Moment(dateEffet).format("YYYY-MM-DD")}`
    /*    const url = `http://localhost:8080/api/devis/dda-questions`*/
    return Axios.post(url, devis)
  }
}
