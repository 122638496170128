import * as Yup from "yup"

const QuotationLandingValidation =
    Yup.object().shape({
        cpEmployeurAngentTerritotrial: Yup.string(),
        employeurAngentTerritotrial: Yup.string(),
        tns: Yup.boolean(),
        agentTerritorial: Yup.boolean(),
        besoinTNS: Yup.string().when("tns", {
            is: true,
            then: Yup.string()
                .test("test-besoinTNS", "besoinTNS is required", function(value) {
                    return checkSelect(value)
                })
                .required("besoinTNS is required"),
            otherwise: Yup.string().notRequired(),
        }),
        codePostalTNS: Yup.string().when("tns", {
            is: true,
            then: Yup.string()
                .test("test-codePostalTNS", "codePostalTNS is required", function(
                    value
                ) {
                    return checkSelect(value)
                })
                .required("codePostalTNS is required"),
            otherwise: Yup.string().notRequired(),
        }),
        villeTNS: Yup.string().when("tns", {
            is: true,
            then: Yup.string().required("villeTNS is required"),
            otherwise: Yup.string().notRequired(),
        }),
        nomEntrepriseTNS: Yup.string(),
        salariesTNS: Yup.string().when("tns", {
            is: true,
            then: Yup.string()
                .matches(/(true|false)/, { excludeEmptyString: false })
                .required("salariesTNS is required"),
            otherwise: Yup.string().notRequired(),
        }),

        dateNaissance: Yup.string()
            .test(
                "test-majeur",
                "Il n'est pas possible d'adhérer avant 18 ans",
                function(value) {
                    return checkAge(value)
                }
            )
            .required("Il n'est pas possible d'adhérer avant 18 ans"),
        regimeAssuranceMal: Yup.string()
            .test(
                "test-regime-asssurance",
                "regimeAssuranceMal is required",
                function(value) {
                    return checkSelect(value)
                }
            )
            .required("regimeAssuranceMal is required"),
        situationPro: Yup.string()
            .test("test-situationPro", "situationPro is required", function(value) {
                return checkSelect(value)
            })
            .required("situationPro is required"),
        debutContrat: Yup.string().required("debutContrat is required"),
        depassement: Yup.string()
            .matches(/(true|false)/, { excludeEmptyString: false })
            .required("depHonnoraires is required"),
        auditif: Yup.string()
            .matches(/(true|false)/, { excludeEmptyString: false })
            .required("auditif is required"),
        hospi: Yup.string()
            .matches(/(true|false)/, { excludeEmptyString: false })
            .required("hospitalisation is required"),
        prothesesDentaires: Yup.string()
            .matches(/(true|false)/, { excludeEmptyString: false })
            .required("dentaires is required"),
        lunettesLentilles: Yup.string()
            .matches(/(true|false)/, { excludeEmptyString: false })
            .required("lunettes is required"),
        miniCouverture: Yup.string().when("tns", {
            is: true,
            then: Yup.string()
                .matches(/(true|false)/, { excludeEmptyString: false })
                .required("salariesTNS is required"),
            otherwise: Yup.string().notRequired(),
        }),
        miniHospi: Yup.string().when("tns", {
            is: true,
            then: Yup.string()
                .matches(/(true|false)/, { excludeEmptyString: false })
                .required("salariesTNS is required"),
            otherwise: Yup.string().notRequired(),
        }),
        couvertureZen: Yup.string().when("zen", {
            is: true,
            then: Yup.string()
                .required("couvertureZen is required"),
            otherwise: Yup.string().notRequired(),
        }),
        politiquementExpose: Yup.string()
            .matches(/(true|false)/, { excludeEmptyString: false })
            .required("lunettes is required"),
        conjointChecked: Yup.boolean(),
        regimeAssuranceMalConjoint: Yup.string().when("conjointChecked", {
            is: true,
            then: Yup.string()
                .test(
                    "test-regime-asssurance",
                    "regimeAssuranceMal is required for conjoint",
                    function(value) {
                        return checkSelect(value)
                    }
                )
                .required(),
            otherwise: Yup.string().notRequired(),
        }),
        dateNaissanceConjoint: Yup.string().when("conjointChecked", {
            is: true,
            then: Yup.string()
                .test(
                    "test-majeur",
                    "Il n'est pas possible d'adhérer avant 18 ans",
                    function(value) {
                        return checkAge(value)
                    }
                )
                .required(),
            otherwise: Yup.string().notRequired(),
        }),
        situationProConjoint: Yup.string().when("conjointChecked", {
            is: true,
            then: Yup.string()
                .test(
                    "test-situationPro",
                    "situationPro is conjoint required",
                    function(value) {
                        return checkSelect(value)
                    }
                )
                .required(),
            otherwise: Yup.string().notRequired(),
        }),

        childrenChecked: Yup.boolean(),
        nbEnfantsPlus1an: Yup.number(),
        nbEnfantsMoisn1an: Yup.number(),
        regimeAssuranceMalEnfant1: Yup.string().when("childrenChecked", {
            is: true,
            then: Yup.string().when("nbEnfantsPlus1an", {
                is: nbEnfantsPlus1an => nbEnfantsPlus1an > 0,
                then: Yup.string()
                    .test(
                        "test-regimeAssuranceMalEnfant1",
                        "regimeAssuranceMalEnfant1 is conjoint required",
                        function(value) {
                            return checkSelect(value)
                        }
                    )
                    .required("regimeAssuranceMalEnfant1 is requered"),
                otherwise: Yup.string().notRequired(),
            }),
            otherwise: Yup.string().notRequired(),
        }),
        regimeAssuranceMalEnfant2: Yup.string().when("childrenChecked", {
            is: true,
            then: Yup.string().when("nbEnfantsPlus1an", {
                is: nbEnfantsPlus1an => nbEnfantsPlus1an > 1,
                then: Yup.string()
                    .test(
                        "test-regimeAssuranceMalEnfant2",
                        "regimeAssuranceMalEnfant2 is conjoint required",
                        function(value) {
                            return checkSelect(value)
                        }
                    )
                    .required("regimeAssuranceMalEnfant2 is requered"),
                otherwise: Yup.string().notRequired(),
            }),
            otherwise: Yup.string().notRequired(),
        }),    dateFinDernierContrat: Yup.date().when(["agentTerritorial", "dejaCouvert"],{
            is: (agentTerritorial, dejaCouvert) => {return agentTerritorial+'' === 'true' && dejaCouvert+'' === 'true'},
            then: Yup.date().required(),
            otherwise: Yup.date().notRequired(),
        }),    dateEntreeFonctionPublique: Yup.date().when(["agentTerritorial", "dejaCouvert"],{
            is: (agentTerritorial, dejaCouvert) => {return agentTerritorial+'' === 'true' && dejaCouvert+'' === 'false'},
            then: Yup.date().required(),
            otherwise: Yup.date().notRequired(),
        }),
        dejaCouvert: Yup.string().when("agentTerritorial",{
            is: true,
            then: Yup.string()
                .matches(/(true|false)/, { excludeEmptyString: false })
                .required('dejaCouvert is requierd'),
            otherwise: Yup.string().notRequired(),
        }),
    })

export default QuotationLandingValidation

function checkSelect(val) {
    if (val !== "null" && val) return val
    return false
}

function checkAge(birthday) {
    let age = Date.parse(birthday)
    let ageDifMs = Date.now() - age
    let ageDate = new Date(ageDifMs)

    if (ageDate.getUTCFullYear() - 1970 >= 18) {
        return birthday
    } else {
        return false
    }
}
