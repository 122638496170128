import moment from "moment"

export default class QuotationMapper {

  static toDevisDTO(values, dateEffet) {

    let entrepriseTNS = null
    if (values.tns) {
      entrepriseTNS = {
        codePostal: values.codePostalTNS,
        ville: values.villeTNS,
        nom: values.nomEntrepriseTNS,
        salaries: values.salariesTNS,
        besoins: values.besoinTNS,
      }
    }

    let conjoint
    if (values.conjointChecked) {
      conjoint = {
        dateNaissance: moment(values.dateNaissanceConjoint).format('YYYY-MM-DD'),
        regimeAssurance: values.regimeAssuranceMalConjoint,
        situationProfessionnelle: values.situationProConjoint,
      }
    }

    let enfant1
    const d = new Date()
    if (values.childrenChecked && values.nbEnfantsPlus1an >= 1) {
      let date = new Date(d.getFullYear(), d.getMonth(), 1)
      let dat = new Date(date.setYear(date.getFullYear() - 10))
      let fr_date = dat.toLocaleDateString("fr-FR")
      fr_date = fr_date.split("/")
      let us_date = fr_date.reverse().join("-")
      enfant1 = {
        dateNaissance: us_date,
        regimeAssurance: values.regimeAssuranceMalEnfant1,
        situationProfessionnelle: "",
      }
    }

    let enfant2
    if (values.childrenChecked && values.nbEnfantsPlus1an >= 2) {
      let date = new Date(d.getFullYear(), d.getMonth(), 1)
      let dat = new Date(date.setYear(date.getFullYear() - 11))
      let fr_date = dat.toLocaleDateString("fr-FR")
      fr_date = fr_date.split("/")
      let us_date = fr_date.reverse().join("-")
      enfant2 = {
        dateNaissance: us_date,
        regimeAssurance: values.regimeAssuranceMalEnfant2,
        situationProfessionnelle: "",
      }
    }

    let agentTerritorial = null
    if (values.agentTerritorial) {
      agentTerritorial = {
        codePostalEmployeur: values.cpEmployeurAngentTerritotrial,
        employeur: values.employeurAngentTerritotrial,
      }
    }

    return {
      agentTerritorial: agentTerritorial,
      entrepriseTNS: entrepriseTNS,
      nbEnfantsPlus1an: values.nbEnfantsPlus1an || 0,
      dateEffet: dateEffet || values.debutContrat,
      nbEnfantsMoisn1an: values.nbEnfantsMoisn1an || 0,
      beneficiaire: {
        dateNaissance: moment(values.dateNaissance).format('YYYY-MM-DD'),
        regimeAssurance: values.regimeAssuranceMal,
        situationProfessionnelle: values.situationPro,
        consultationDepHonoraire: values.depHonnoraires,
        lunette: values.lunettes,
        soisnDentaire: values.dentaires,
        hospitalisation: values.hospitalisation,
        audition: values.auditif,
        politiquementExpose: values.politiquementExpose,
      },
      conjoint: conjoint,
      enfant1: enfant1,
      enfant2: enfant2,
    }
  }
}
